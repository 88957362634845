import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, Validators, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NewActionButtonComponent } from 'src/app/shared/components/new-action-button/new-action-button.component';
import { Subscription, throwError } from 'rxjs';
import { UserLoginParams } from '@models/user.model';
import { AuthButtonsComponent } from '@shared/components/auth-buttons/auth-buttons.component';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserService } from '@root/services/user-service/user.service';
import { tapResponse } from '@ngrx/operators';
import { JwtAuthService } from '@root/services/auth/jwt-auth.service';
import { GeneralState, GeneralStore } from '@root/store/general.store';
import { GlobalPermissions, LocalPermissions } from '@root/models/global-enum';
import { MatInputModule } from '@angular/material/input';
import { CheckCircle2, LucideAngularModule } from 'lucide-angular';
import { LucideIconData } from 'lucide-angular/icons/types';
import LogRocket from 'logrocket';
import { LocationsStore } from '@root/store/location.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NewActionButtonComponent,
    MatButtonModule,
    AuthButtonsComponent,
    InputErrorComponent,
    NgOptimizedImage,
    MatInputModule,
    LucideAngularModule,
  ],
})
export class LoginComponent implements OnDestroy, OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly authService = inject(JwtAuthService);
  private readonly cdr = inject(ChangeDetectorRef);
  readonly CheckCircle2: LucideIconData = CheckCircle2;
  errorSub: Subscription | undefined;
  errorMessage = '';
  loginForm: FormGroup;
  hideInput: boolean = false;
  forgotPassword: FormGroup;
  forgotPasswordMsg: boolean = false;
  showLogin: boolean = true;
  showResetPassMsg: boolean = false;
  disableButton: boolean = false;
  readonly userService: UserService = inject(UserService);
  readonly generalStore = inject(GeneralStore);
  readonly locationStore = inject(LocationsStore);
  public isOffline: boolean = !navigator.onLine;
  otherLocalPermissions: boolean = false;

  constructor() {
    this.loginForm = new FormGroup({
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });

    this.loginForm.valueChanges.subscribe(() => {
      this.errorMessage = '';
    });

    this.forgotPassword = new FormGroup({
      username: new FormControl('', Validators.required),
    });

    // TODO: make sure all errors are handled

    this.route.queryParams.pipe(takeUntilDestroyed()).subscribe(params => {
      if (params && params['sso_login_fail']) {
        this.errorMessage = 'Login failed';
      }

      if (params && params['access_token']) {
        this.authService.setUserToken(params['access_token']);
        this.authService.setRefreshToken(params['refresh_token']);
        this.postLoginAction();
      }

      if (params && params['reset_pass']) {
        this.showResetPassMsg = true;
      }
    });
  }
  ngOnInit(): void {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  }

  private updateOnlineStatus = (): void => {
    this.isOffline = !navigator.onLine;
  };
  onSubmit() {
    if (this.loginForm.valid) {
      const loginPayload: UserLoginParams = {
        username: this.username?.value,
        password: this.password?.value,
      };

      this.userService.login(loginPayload).subscribe({
        next: () => {
          this.postLoginAction();
        },
        error: error => {
          this.errorMessage = 'Invalid login credentials. Please try again';
          return throwError(() => error);
        },
      });
    }
  }

  postLoginAction() {
    this.generalStore.getCurrentUser();
    this.generalStore.loadedNotifier().subscribe((newState: GeneralState) => {
      this.locationStore.getEntityData();
      LogRocket.identify(`${newState.userInfo.userID}`, {
        firstName: newState.userInfo.userFirstName,
        lastName: newState.userInfo.userLastName,
        email: newState.userInfo.userEmail,
        tenantName: newState.userInfo.customer.customerName,
      });
      let redirectLink: string;
      if (newState.isTaskBoardUser) {
        redirectLink = `/locations/${newState.userInfo.userDefaultLocationID}/taskboard`;
      } else if (
        newState.globalCreds?.indexOf(GlobalPermissions.GLOBAL_ADMIN) > -1 ||
        newState.globalCreds?.indexOf(GlobalPermissions.GLOBAL_VIEWER) > -1
      ) {
        if (newState.assignedLocations.length > 0) {
          redirectLink = '/global/dashboard';
        } else {
          redirectLink = '/global/no-locations';
        }
      } else if (newState.locationCreds.size > 0) {
        let foundLocation: boolean = false;
        newState.locationCreds.forEach((creds: number[], locationId: number) => {
          if (
            (creds.indexOf(LocalPermissions.LOCAL_ADMIN) > -1 ||
              creds.indexOf(LocalPermissions.LOCAL_VIEWER) > -1) &&
            !foundLocation
          ) {
            redirectLink = `/locations/${locationId}/local-dashboard`;
            foundLocation = true;
          } else if (this.otherlocalPermissions(creds, locationId)) {
            redirectLink = `/locations/${locationId}/my-tasks`;
            foundLocation = true;
          }
        });
      } else if (
        newState.globalCreds?.indexOf(GlobalPermissions.REPORT_ADMIN) > -1 ||
        newState.globalCreds?.indexOf(GlobalPermissions.REPORT_VIEWER) > -1
      ) {
        redirectLink = '/global/reports';
      } else if (newState.assignedLocations.length > 0) {
        redirectLink = `/locations/${newState.assignedLocations[0].locationID}/my-tasks`;
      } else {
        redirectLink = '/no-permissions';
      }
      if (redirectLink) {
        this.router.navigate([redirectLink]);
        this.cdr.detectChanges();
      }
    });
  }

  otherlocalPermissions(creds: number[], locationId: number): boolean {
    const locationPermissions: number[] = this.generalStore.locationCreds().get(locationId);
    const permissionsToCheck: number[] = [
      LocalPermissions.ALLOW_TASK_SCORING,
      LocalPermissions.ASSIGN_TASK_AND_SETS_TO_OTHERS,
      LocalPermissions.COMPLETE_OTHERS_TASKS,
      LocalPermissions.REASSIGN_MY_TASKS_AND_TASK_SETS_TO_OTHERS,
      LocalPermissions.DELETE_SPAWNED_TASKS,
      LocalPermissions.SPAWN_FROM_EVENT_WIZARD,
    ];
    const hasPermission: boolean = locationPermissions.some(permission =>
      permissionsToCheck.includes(permission),
    );
    if (hasPermission) {
      return true;
    }
    return false;
  }

  onSubmitForgetPassword() {
    // TODO: verify this flow is working as expected
    if (this.forgotPassword.valid) {
      this.userService
        .forgotPassword(this.forgotPasswordEmail?.value)
        .pipe(
          tapResponse({
            next: _data => {
              this.disableButton = true;
              setTimeout(() => {
                this.disableButton = false;
              }, 1500);
            },
            error: () => {
              this.disableButton = true;
              setTimeout(() => {
                this.disableButton = false;
              }, 1500);
            },
          }),
        )
        .subscribe();
      this.forgotPasswordMsg = true;
      this.hideInput = true;
    }
  }

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  get forgotPasswordEmail() {
    return this.forgotPassword.get('username');
  }

  ngOnDestroy(): void {
    this.errorSub?.unsubscribe();
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  }

  goBack() {
    this.showLogin = true;
    this.forgotPasswordMsg = false;
    this.hideInput = false;
    this.errorMessage = '';
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.showLogin = false;
      this.loginForm.reset();
    }
  }
}
